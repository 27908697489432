import Container from "../../../../src/components/layout/container";
import Layout from "../../../../src/components/layout/layout";
import Seo from "../../../../src/components/seo/seo";
import Content from "../../../../src/components/ui/content";
import PageHeader from "../../../../src/components/ui/page-header";
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
export default {
  Container,
  Layout,
  Seo,
  Content,
  PageHeader,
  StaticImage,
  React
};