// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-fabrika-js": () => import("./../../../src/pages/fabrika.js" /* webpackChunkName: "component---src-pages-fabrika-js" */),
  "component---src-pages-factory-en-js": () => import("./../../../src/pages/factory.en.js" /* webpackChunkName: "component---src-pages-factory-en-js" */),
  "component---src-pages-hakkimizda-js": () => import("./../../../src/pages/hakkimizda.js" /* webpackChunkName: "component---src-pages-hakkimizda-js" */),
  "component---src-pages-hosgeldiniz-js": () => import("./../../../src/pages/hosgeldiniz.js" /* webpackChunkName: "component---src-pages-hosgeldiniz-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kvkk-mdx": () => import("./../../../src/pages/kvkk.mdx" /* webpackChunkName: "component---src-pages-kvkk-mdx" */),
  "component---src-pages-orjinalindex-js": () => import("./../../../src/pages/orjinalindex.js" /* webpackChunkName: "component---src-pages-orjinalindex-js" */),
  "component---src-pages-products-en-js": () => import("./../../../src/pages/products.en.js" /* webpackChunkName: "component---src-pages-products-en-js" */),
  "component---src-pages-urunler-js": () => import("./../../../src/pages/urunler.js" /* webpackChunkName: "component---src-pages-urunler-js" */),
  "component---src-pages-wellcome-js": () => import("./../../../src/pages/wellcome.js" /* webpackChunkName: "component---src-pages-wellcome-js" */)
}

