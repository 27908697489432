import React from 'react'
import { Link } from 'gatsby'
const Footer = ({ siteTitle }) => {
  return (
    <footer className='w-full   footer pt-10'>
      <div className='w-full py-5'>
        <div className='container mx-auto text-xs flex flex-wrap '>
          <div className='w-1/2'>
            &copy; {siteTitle} 2015 - {new Date().getFullYear()}
            <span className='font-bold mx-2'>{siteTitle}</span>
            All rights reserved.{' '}
            <Link href='/kvkk/' className='ml-3'>
              <a>KVKK</a>
            </Link>
          </div>
          <div className='w-1/2 flex flex-wrap items-center justify-end'>
            <Link href='https://erkasoft.com'>
              <a>Erkasoft</a>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
