import React from 'react'
import PropTypes from 'prop-types'
import Container from '../layout/container'

const PageHeader = ({ title, description }) => {
  return (
    <div
      className='w-full bg-no-repeat bg-cover bg-center py-10'
      style={{
        backgroundImage: 'url(' + '/images/page-header-bg.jpg' + ')',
      }}
    >
      <Container className='my-20 mb-5 text-center   '>
        <h1 className='text-6xl py-10  font-light mb-3 text-white  bg-gray-800 bg-opacity-50'>
          {title}
        </h1>
        <p className='text-sm'>{description}</p>
      </Container>
    </div>
  )
}
PageHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

PageHeader.defaultProps = {
  title: ``,
  description: ``,
}

export default PageHeader
