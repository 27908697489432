export const MAIN_MENU = [
  { href: '/', label: 'Giriş' },
  { href: '/hakkimizda/', label: 'Hakkımızda' },
  { href: '/urunler/', label: 'Ürünler' },
  { href: '/fabrika/', label: 'Fabrika' },
  { href: '/iletisim/', label: 'İletişim' },
]

export const FOOTER_MENU = [
  { href: '/', label: 'Giriş' },
  { href: '/hakkimizda/', label: 'Hakkımızda' },
  { href: '/urunler/', label: 'Ürünler' },
  { href: '/fabrika/', label: 'Fabrika' },
  { href: '/iletisim/', label: 'İletişim' },
]

export const MAIN_MENU_EN = [
  { href: '/', label: 'Home' },
  { href: '/en/about/', label: 'About' },
  { href: '/en/products/', label: 'Products' },
  { href: '/en/factory/', label: 'Factory' },
  { href: '/en/contact/', label: 'Contact' },
]
